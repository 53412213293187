@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#default-sidebar {
   background-color: var(--dark-blue);
   min-width: 400px;
}

.e-treeview .e-list-item.e-active>.e-fullrow {
   background-color: var(--light-orange) !important;
   border-color: var(--light-orange)!important;
}

.e-list-item e-level-1 e-has-child e-active e-node-focus {
   background-color: var(--light-orange) !important;
   border-color: var(--light-orange)!important;
}

.e-list-item .e-hover>.e-fullrow {
   background-color: var(--light-orange) !important;
   border-color: var(--light-orange)!important;
}

/* .e-fullrow .e-hover {
    background-color: rgb(5, 159, 197) !important;
    border-color: rgb(5, 159, 197) !important;
 } */

.e-treeview .e-list-item.e-active>.e-fullrow, .e-treeview .e-list-item.e-hover>.e-fullrow {
   background-color: var(--light-orange) !important;
   border-color: var(--light-orange)!important;
}

/* TEMPLATE NODE  */

.templateNode .e-btn.small {
   width: 20px;
   height: 20px;
   background-color: var(--light-orange);
}

.e-text-content.e-icon-wrapper {
   display: flex;
}

.templateNode .e-round.e-btn .e-plus-icon::before {
   color: var(--blue);
   content: '\e823';
}

.templateNode .e-round.e-btn .e-plus-icon {
   line-height: 0;
   display: flex;
   align-items: center;
   justify-content: center;
}

.bg-dark-blue.e-btn:hover {
   background-color: var(--orange);
}

.bg-dark-blue.e-btn:focus {
   background-color: var(--dark-blue);
   color: var(--white);
}

#default-sidebar .e-treeview .e-icon-collapsible, #default-sidebar .e-treeview .e-icon-expandable {
   color: var(--white)
}

#default-sidebar .e-btn.e-primary:hover, #default-sidebar .e-css.e-btn.e-primary:hover {
   background-color: var(--dark-blue);
}

#default-sidebar .sidebar-loading {
   font-size: 21px;
   display: flex;
   place-content: center;
   height: 100%;
   align-items: center;
}

 /* SCROLLBAR */
 #default-sidebar::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
#default-sidebar::-webkit-scrollbar-track {
  background: #f1f1f1; 
  border-radius: 10px;
}
 
/* Handle */
#default-sidebar::-webkit-scrollbar-thumb {
  background: rgb(199, 199, 199); 
  border-radius: 10px;
}

#default-sidebar .e-icons.e-icon-expandable,#default-sidebar .e-icon-collapsible {
   align-self: center;
   display: flex;
}

#default-sidebar .e-list-text {
   display: flex;
}


.title {
    padding-top: 2%
}

.name-description {
    padding-top: 2%;
}

.checkbox {
    padding-top: 4%;
    padding-bottom: 1%;
}

.subtitle {
    padding-bottom: 2%;
}

.sutitle-template {
    padding-bottom: 5%;
    padding-top: 5%;
}

.close-inputs {
    padding-bottom: 1%;
}

.submit-button {
    padding-top: 3%;
    padding-left: 17%;
}

.error-message {
    color: red;
}

.btn-primary.bg-dark-blue {
    color: #fff;
    background-color: var(--orange);
    border-color: var(--orange);
}
.btn-primary.bg-dark-blue:hover {
    color: #fff;
    background-color: var(--light-orange);
    border-color: var(--orange);
}

.coordinates {
    padding-top: 30px;
    display: flex;
}

.visible-dashboard {
    align-self: end;
}

.wrap {
    overflow-wrap: break-word;
}

.check {
    color: green;
}
.first-row {
    padding-top: 3%;
}

.submit-button {
    padding-bottom: 15px;
}
.btn-primary.bg-dark-blue {
    color: #fff;
    background-color: var(--orange);
    border-color: var(--orange);
}
.btn-primary.bg-dark-blue:hover {
    color: #fff;
    background-color: var(--light-orange);
    border-color: var(--orange);
}
.button:hover {
    cursor: pointer;
}

.btn-primary.bg-dark-blue {
    color: #fff;
    background-color: var(--orange);
    border-color: var(--orange);
}
.btn-primary.bg-dark-blue:hover {
    color: #fff;
    background-color: var(--light-orange);
    border-color: var(--orange);
}

.no-labels-text {
    padding-top: 3%;
    padding-bottom: 3%;
}

#labels-table .scrollable {
    overflow: scroll;
    max-height: 400px;
  }

   /* SCROLLBAR */
 #labels-table::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  
  /* Track */
  #labels-tabletable::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 10px;
  }
   
  /* Handle */
  #labels-table::-webkit-scrollbar-thumb {
    background: rgb(199, 199, 199); 
    border-radius: 10px;
  }

.scrollable {
  overflow: scroll;
  max-height: 400px;
}

.scrollable-small {
  overflow: scroll;
  max-height: 140px;
}

/* SCROLLBAR */

#display-table::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */

#display-table::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */

#display-table::-webkit-scrollbar-thumb {
  background: rgb(199, 199, 199);
  border-radius: 10px;
}
#tooltip-icon:hover {
    cursor: pointer;
}

#tooltip-icon {
    height: 16px;
    width: 16px;
    object-fit: contain;
    display: block;
}

.tooltip-button.btn-primary {
    background-color: transparent;
    border: none;
    box-shadow: none;
    color: black;
    padding: 0;
}

.tooltip-button.btn-check:focus+.btn-primary, .tooltip-button.btn-primary:focus {
    color: black;
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
}
#food-groups-table .scrollable {
    overflow: scroll;
    max-height: 400px;
  }

   /* SCROLLBAR */
 #food-groups-table::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  
  /* Track */
  #food-groups-table::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 10px;
  }
   
  /* Handle */
  #food-groups-table::-webkit-scrollbar-thumb {
    background: rgb(199, 199, 199); 
    border-radius: 10px;
  }
.scrollable {
    overflow: scroll;
    max-height: 400px;
  }
  
  .scrollable-small {
    overflow: scroll;
    max-height: 140px;
  }
  
  /* SCROLLBAR */
  
  #display-table::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  
  /* Track */
  
  #display-table::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }
  
  /* Handle */
  
  #display-table::-webkit-scrollbar-thumb {
    background: rgb(199, 199, 199);
    border-radius: 10px;
  }
/*GOOGLE FONTS*/


:root {
  --black: #16222C;
  --dark-grey: #737A80;
  --medium-grey: #C0C3D1;
  --light-grey: #e8e8e8;
  --white: #ffffff;
  --dark-blue: #1b1a18;
  --orange:#f07e51; 
  --light-orange:#ffa17c;
  --light-green:#47D7B6;
  --redError:#EC373D;
  --greenSuccess:#35cf94;
  --yellowWarning: #F7B842;  

}
  body{
  font-family: 'Lato'  !important;
  min-height: 100vh;
background-color:#e8e8e8 !important ;
background-color:var(--light-grey) !important ;
}
* .e-control, .e-css{
  font-family: 'Lato'  !important;
}
.bg-white{
  background-color: #ffffff;
  background-color: var(--white);
}
.bg-dark-blue{
  background-color: #1b1a18;
  background-color: var(--dark-blue);
}
.bg-orange{
  background-color: #f07e51;
  background-color: var(--orange);
}
.bg-light-orange{
  background-color: var(--red);
}

.bg-green{
  background-color: var(--green);
}
.bg-medium-grey{
  background-color: #C0C3D1;
  background-color: var(--medium-grey);
}




.c-black { color: #16222C; color: var(--black); }
.c-dark-grey { color: #737A80; color: var(--dark-grey); }
.c-medium-grey { color: #C0C3D1; color: var(--medium-grey); }
.c-red { color:var(--red); }
.c-green { color:var(--green); }
.c-purple { color:var(--purple); }
.c-blue { color:var(--blue); }
.c-tiffany { color:var(--tiffany); }
.c-violet { color:var(--violet); }
.c-dark-violet { color:var(--dark-violet); }
.c-heavenly { color:var(--celeste); }
.c-light-green { color:#47D7B6; color:var(--light-green); }
.c-white { color: #ffffff; color: var(--white); }


.font-weight-bold { font-weight: 800; }
.font-weight-semibold { font-weight: 600; }
.font-weight-regular { font-weight: 400; }
@media screen and (max-width: 768px) {
.font-weight-sm-bold { font-weight: 800; }
.font-weight-sm-semibold { font-weight: 600; }
.font-weight-sm-regular { font-weight: 400; }
}
.font-weight-sm-bold,.font-weight-semibold, .font-weight-regular {
  line-height: 1.3;
}
.font-size-46 { font-size: 46px; }
.font-size-38 { font-size: 38px; }
.font-size-36 { font-size: 36px; }
.font-size-32 { font-size: 32px; }
.font-size-28 { font-size: 28px; }
.font-size-24 { font-size: 24px; }
.font-size-22 { font-size: 22px; }
.font-size-21 { font-size: 21px; }
.font-size-20 { font-size: 20px; }
.font-size-18 { font-size: 18px; }
.font-size-16 { font-size: 16px; }
.font-size-15 { font-size: 15px; }
.font-size-14 { font-size: 14px; }
.font-size-13 { font-size: 13px; }
.font-size-12 { font-size: 12px; }
.font-size-11 { font-size: 11px; }
@media screen and (max-width: 768px) {
  .font-size-sm-46 { font-size: 46px; }
  .font-size-sm-38 { font-size: 38px; }
  .font-size-sm-36 { font-size: 36px; }
  .font-size-sm-32 { font-size: 32px; }
  .font-size-sm-28 { font-size: 28px; }
  .font-size-sm-24 { font-size: 24px; }
  .font-size-sm-22 { font-size: 21px; }
  .font-size-sm-21 { font-size: 21px; }
  .font-size-sm-20 { font-size: 20px; }
  .font-size-sm-18 { font-size: 18px; }
  .font-size-sm-16 { font-size: 16px; }
  .font-size-sm-15 { font-size: 15px; }
  .font-size-sm-14 { font-size: 14px; }
  .font-size-sm-13 { font-size: 13px; }
  .font-size-sm-12 { font-size: 12px; }
  .font-size-sm-11 { font-size: 11px; }
  }


.img-icon100{
  width: 100px;
  height: 100px;
  object-fit: contain;
  }
  .img-icon90{
    width: 90px;
    height: 90px;
    object-fit: contain;
    }
    .img-logo80{
      width: 80px;
      object-fit: contain;
    }
.img-icon80{
  width: 80px;
  height: 80px;
  object-fit: contain;
  }
.img-icon60{
  width: 60px;
  height: 60px;
  object-fit: contain;
}
.img-icon50{
  height: 50px;
  object-fit: contain;
}
.img-icon40{
  width: 40px;
  height: 40px;
  object-fit: contain;
}
.img-icon35{
  height: 35px;
  width: 35px;
  object-fit: contain;
  }
.img-icon30{
  height: 30px;
  width: 30px;
  object-fit: contain;
  }
.img-icon25{
  height: 25px;
  width: 25px;
  object-fit: contain;
  }
.img-icon20{
  height: 20px;
  width: 20px;
  object-fit: contain;
}
.img-icon15{
  height: 15px;
  width: 15px;
  object-fit: contain;
}
.img-icon12{
  height: 12px;
  width: 12px;
  object-fit: contain;
}

.shadowDropLessBlur{
  box-shadow: 0 2px 10px #16222c0f;
}
.shadowDrop{
  box-shadow: 0 3px 16px #16222c0f;
}
.border-radius{
  border-radius: 15px;
}
/* .border {
  border: black;
  border-width: 3px;
  padding-bottom: 10px;
  padding-left: 10px;
  border-style: solid;
} */
.e-grid .e-hide {
  display: none;
}
.position-absolute{
  position: absolute;
} 
.pointer{
  cursor: pointer;
}
.borderNone{
  border: none;
}

.text-decoration-underline {
  text-decoration: underline;
}
.text-decoration-none {
  text-decoration: none;
}
.not-selectable {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
.mt-n5 {
  margin-top: -2.5rem ;
}
 
/* SCROLLBAR */
.scrollbarCustom ::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
.scrollbarCustom ::-webkit-scrollbar-track {
  background: #f1f1f1; 
  border-radius: 10px;
}
 
/* Handle */
.scrollbarCustom ::-webkit-scrollbar-thumb {
  background: rgb(199, 199, 199); 
  border-radius: 10px;
}
.list-style-none{
  list-style: none;
}

.align-end {
  align-self: end;
}
.login {
    height: 100%;
    /* display: flex; */
    justify-content: center;
    align-items: center;
    padding-top: 20%;
}
