#food-groups-table .scrollable {
    overflow: scroll;
    max-height: 400px;
  }

   /* SCROLLBAR */
 #food-groups-table::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  
  /* Track */
  #food-groups-table::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 10px;
  }
   
  /* Handle */
  #food-groups-table::-webkit-scrollbar-thumb {
    background: rgb(199, 199, 199); 
    border-radius: 10px;
  }