@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';  
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';  
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';  
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import "../node_modules/@syncfusion/ej2-grids/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-treegrid/styles/material.css";
@import '../node_modules/@syncfusion/ej2-base/styles/material.css';  
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';  
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';  
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';  
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';  
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import "../node_modules/@syncfusion/ej2-react-grids/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-dropdowns/styles/material.css";

/*GOOGLE FONTS*/
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');


:root {
  --black: #16222C;
  --dark-grey: #737A80;
  --medium-grey: #C0C3D1;
  --light-grey: #e8e8e8;
  --white: #ffffff;
  --dark-blue: #1b1a18;
  --orange:#f07e51; 
  --light-orange:#ffa17c;
  --light-green:#47D7B6;
  --redError:#EC373D;
  --greenSuccess:#35cf94;
  --yellowWarning: #F7B842;  

}
  body{
  font-family: 'Lato'  !important;
  min-height: 100vh;
background-color:var(--light-grey) !important ;
}
* .e-control, .e-css{
  font-family: 'Lato'  !important;
}
.bg-white{
  background-color: var(--white);
}
.bg-dark-blue{
  background-color: var(--dark-blue);
}
.bg-orange{
  background-color: var(--orange);
}
.bg-light-orange{
  background-color: var(--red);
}

.bg-green{
  background-color: var(--green);
}
.bg-medium-grey{
  background-color: var(--medium-grey);
}




.c-black { color: var(--black); }
.c-dark-grey { color: var(--dark-grey); }
.c-medium-grey { color: var(--medium-grey); }
.c-red { color:var(--red); }
.c-green { color:var(--green); }
.c-purple { color:var(--purple); }
.c-blue { color:var(--blue); }
.c-tiffany { color:var(--tiffany); }
.c-violet { color:var(--violet); }
.c-dark-violet { color:var(--dark-violet); }
.c-heavenly { color:var(--celeste); }
.c-light-green { color:var(--light-green); }
.c-white { color: var(--white); }


.font-weight-bold { font-weight: 800; }
.font-weight-semibold { font-weight: 600; }
.font-weight-regular { font-weight: 400; }
@media screen and (max-width: 768px) {
.font-weight-sm-bold { font-weight: 800; }
.font-weight-sm-semibold { font-weight: 600; }
.font-weight-sm-regular { font-weight: 400; }
}
.font-weight-sm-bold,.font-weight-semibold, .font-weight-regular {
  line-height: 1.3;
}
.font-size-46 { font-size: 46px; }
.font-size-38 { font-size: 38px; }
.font-size-36 { font-size: 36px; }
.font-size-32 { font-size: 32px; }
.font-size-28 { font-size: 28px; }
.font-size-24 { font-size: 24px; }
.font-size-22 { font-size: 22px; }
.font-size-21 { font-size: 21px; }
.font-size-20 { font-size: 20px; }
.font-size-18 { font-size: 18px; }
.font-size-16 { font-size: 16px; }
.font-size-15 { font-size: 15px; }
.font-size-14 { font-size: 14px; }
.font-size-13 { font-size: 13px; }
.font-size-12 { font-size: 12px; }
.font-size-11 { font-size: 11px; }
@media screen and (max-width: 768px) {
  .font-size-sm-46 { font-size: 46px; }
  .font-size-sm-38 { font-size: 38px; }
  .font-size-sm-36 { font-size: 36px; }
  .font-size-sm-32 { font-size: 32px; }
  .font-size-sm-28 { font-size: 28px; }
  .font-size-sm-24 { font-size: 24px; }
  .font-size-sm-22 { font-size: 21px; }
  .font-size-sm-21 { font-size: 21px; }
  .font-size-sm-20 { font-size: 20px; }
  .font-size-sm-18 { font-size: 18px; }
  .font-size-sm-16 { font-size: 16px; }
  .font-size-sm-15 { font-size: 15px; }
  .font-size-sm-14 { font-size: 14px; }
  .font-size-sm-13 { font-size: 13px; }
  .font-size-sm-12 { font-size: 12px; }
  .font-size-sm-11 { font-size: 11px; }
  }


.img-icon100{
  width: 100px;
  height: 100px;
  object-fit: contain;
  }
  .img-icon90{
    width: 90px;
    height: 90px;
    object-fit: contain;
    }
    .img-logo80{
      width: 80px;
      object-fit: contain;
    }
.img-icon80{
  width: 80px;
  height: 80px;
  object-fit: contain;
  }
.img-icon60{
  width: 60px;
  height: 60px;
  object-fit: contain;
}
.img-icon50{
  height: 50px;
  object-fit: contain;
}
.img-icon40{
  width: 40px;
  height: 40px;
  object-fit: contain;
}
.img-icon35{
  height: 35px;
  width: 35px;
  object-fit: contain;
  }
.img-icon30{
  height: 30px;
  width: 30px;
  object-fit: contain;
  }
.img-icon25{
  height: 25px;
  width: 25px;
  object-fit: contain;
  }
.img-icon20{
  height: 20px;
  width: 20px;
  object-fit: contain;
}
.img-icon15{
  height: 15px;
  width: 15px;
  object-fit: contain;
}
.img-icon12{
  height: 12px;
  width: 12px;
  object-fit: contain;
}

.shadowDropLessBlur{
  box-shadow: 0 2px 10px #16222c0f;
}
.shadowDrop{
  box-shadow: 0 3px 16px #16222c0f;
}
.border-radius{
  border-radius: 15px;
}
/* .border {
  border: black;
  border-width: 3px;
  padding-bottom: 10px;
  padding-left: 10px;
  border-style: solid;
} */
.e-grid .e-hide {
  display: none;
}
.position-absolute{
  position: absolute;
} 
.pointer{
  cursor: pointer;
}
.borderNone{
  border: none;
}

.text-decoration-underline {
  text-decoration: underline;
}
.text-decoration-none {
  text-decoration: none;
}
.not-selectable {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
.mt-n5 {
  margin-top: -2.5rem ;
}
 
/* SCROLLBAR */
.scrollbarCustom ::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
.scrollbarCustom ::-webkit-scrollbar-track {
  background: #f1f1f1; 
  border-radius: 10px;
}
 
/* Handle */
.scrollbarCustom ::-webkit-scrollbar-thumb {
  background: rgb(199, 199, 199); 
  border-radius: 10px;
}
.list-style-none{
  list-style: none;
}

.align-end {
  align-self: end;
}