#tooltip-icon:hover {
    cursor: pointer;
}

#tooltip-icon {
    height: 16px;
    width: 16px;
    object-fit: contain;
    display: block;
}

.tooltip-button.btn-primary {
    background-color: transparent;
    border: none;
    box-shadow: none;
    color: black;
    padding: 0;
}

.tooltip-button.btn-check:focus+.btn-primary, .tooltip-button.btn-primary:focus {
    color: black;
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
}