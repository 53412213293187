.title {
    padding-top: 2%
}

.name-description {
    padding-top: 2%;
}

.checkbox {
    padding-top: 4%;
    padding-bottom: 1%;
}

.subtitle {
    padding-bottom: 2%;
}

.sutitle-template {
    padding-bottom: 5%;
    padding-top: 5%;
}

.close-inputs {
    padding-bottom: 1%;
}

.submit-button {
    padding-top: 3%;
    padding-left: 17%;
}

.error-message {
    color: red;
}

.btn-primary.bg-dark-blue {
    color: #fff;
    background-color: var(--orange);
    border-color: var(--orange);
}
.btn-primary.bg-dark-blue:hover {
    color: #fff;
    background-color: var(--light-orange);
    border-color: var(--orange);
}

.coordinates {
    padding-top: 30px;
    display: flex;
}

.visible-dashboard {
    align-self: end;
}

.wrap {
    overflow-wrap: break-word;
}
