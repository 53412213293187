.button:hover {
    cursor: pointer;
}

.btn-primary.bg-dark-blue {
    color: #fff;
    background-color: var(--orange);
    border-color: var(--orange);
}
.btn-primary.bg-dark-blue:hover {
    color: #fff;
    background-color: var(--light-orange);
    border-color: var(--orange);
}

.no-labels-text {
    padding-top: 3%;
    padding-bottom: 3%;
}

#labels-table .scrollable {
    overflow: scroll;
    max-height: 400px;
  }

   /* SCROLLBAR */
 #labels-table::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  
  /* Track */
  #labels-tabletable::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 10px;
  }
   
  /* Handle */
  #labels-table::-webkit-scrollbar-thumb {
    background: rgb(199, 199, 199); 
    border-radius: 10px;
  }
