.scrollable {
    overflow: scroll;
    max-height: 400px;
  }
  
  .scrollable-small {
    overflow: scroll;
    max-height: 140px;
  }
  
  /* SCROLLBAR */
  
  #display-table::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  
  /* Track */
  
  #display-table::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }
  
  /* Handle */
  
  #display-table::-webkit-scrollbar-thumb {
    background: rgb(199, 199, 199);
    border-radius: 10px;
  }