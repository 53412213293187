#default-sidebar {
   background-color: var(--dark-blue);
   min-width: 400px;
}

.e-treeview .e-list-item.e-active>.e-fullrow {
   background-color: var(--light-orange) !important;
   border-color: var(--light-orange)!important;
}

.e-list-item e-level-1 e-has-child e-active e-node-focus {
   background-color: var(--light-orange) !important;
   border-color: var(--light-orange)!important;
}

.e-list-item .e-hover>.e-fullrow {
   background-color: var(--light-orange) !important;
   border-color: var(--light-orange)!important;
}

/* .e-fullrow .e-hover {
    background-color: rgb(5, 159, 197) !important;
    border-color: rgb(5, 159, 197) !important;
 } */

.e-treeview .e-list-item.e-active>.e-fullrow, .e-treeview .e-list-item.e-hover>.e-fullrow {
   background-color: var(--light-orange) !important;
   border-color: var(--light-orange)!important;
}

/* TEMPLATE NODE  */

.templateNode .e-btn.small {
   width: 20px;
   height: 20px;
   background-color: var(--light-orange);
}

.e-text-content.e-icon-wrapper {
   display: flex;
}

.templateNode .e-round.e-btn .e-plus-icon::before {
   color: var(--blue);
   content: '\e823';
}

.templateNode .e-round.e-btn .e-plus-icon {
   line-height: 0;
   display: flex;
   align-items: center;
   justify-content: center;
}

.bg-dark-blue.e-btn:hover {
   background-color: var(--orange);
}

.bg-dark-blue.e-btn:focus {
   background-color: var(--dark-blue);
   color: var(--white);
}

#default-sidebar .e-treeview .e-icon-collapsible, #default-sidebar .e-treeview .e-icon-expandable {
   color: var(--white)
}

#default-sidebar .e-btn.e-primary:hover, #default-sidebar .e-css.e-btn.e-primary:hover {
   background-color: var(--dark-blue);
}

#default-sidebar .sidebar-loading {
   font-size: 21px;
   display: flex;
   place-content: center;
   height: 100%;
   align-items: center;
}

 /* SCROLLBAR */
 #default-sidebar::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
#default-sidebar::-webkit-scrollbar-track {
  background: #f1f1f1; 
  border-radius: 10px;
}
 
/* Handle */
#default-sidebar::-webkit-scrollbar-thumb {
  background: rgb(199, 199, 199); 
  border-radius: 10px;
}

#default-sidebar .e-icons.e-icon-expandable,#default-sidebar .e-icon-collapsible {
   align-self: center;
   display: flex;
}

#default-sidebar .e-list-text {
   display: flex;
}

